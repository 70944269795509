import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiGateway } from "../../../utils/config";
import { handleFormatTimeStamp } from "../../../utils/utils";
import { SERIES_V1_1 } from "../../../utils/constants";
import { CircularProgress } from "@mui/material";
import { checksDefaultState } from "../../../utils/constants/VideoDetails";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faDownload,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import placeholder from "../../../assests/images/placeholder1.png";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import VideoPlayerCustom from "../../VideoPlayer/VideoPlayerCustom";
import NoFeedbackSvj from "../../../assests/images/Feedback/NoFeedback";
import CustomModal from "../CustomModal";
import AllVideoChecks from "./AllVideoChecks";
import useZustandStore from "../../../zustandStore/useZustandStore";

// Made a common component of from old code to play the video
const VideoPlayOnSeriesClick = ({
  thumbnail,
  title,
  n_units,
  approvedChecks,
  slug,
  thumbnailClassName,
  playIconClassName,
  isRemarkInput = false,
  thumbnailWrapperClassName,
  setMessage,
  handleGetUpdatedSeries,
}) => {
  const [playIndex, setPlayIndex] = useState(0);
  const [timeStamp, setTimeStamp] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [videoSource, setVideoSource] = useState(null);
  const [downloadVideoUrl, setDownloadVideoUrl] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [showVideosLoading, setShowVideosLoading] = useState(true);
  const [addRemark, setAddRemark] = useState("");
  const [isLoading, setIsLoading] = useState({ remark: false, checks: false });
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [checksState, setChecksState] = useState(checksDefaultState);
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((state) => state.setToastMessage);
  // const playerRef = useRef(null);
  const videoRef = useRef();

  // videoPlay next
  const handlePlayNextVideo = async (currentPlayIndex, data) => {
    const index = currentPlayIndex >= 0 ? currentPlayIndex : playIndex + 1;
    if (index >= 0 && index < data?.length) {
      const video = data[index];
      const source = video?.content?.h264_media_url
        ? video?.content?.h264_media_url
        : video?.content?.url;
      setDownloadVideoUrl(video?.content?.original_media_url);
      setVideoSource((prev) => source);
      setVideoTitle((prev) => video?.title);
      setPlayIndex((prev) => index);
      setShowVideo(true);
    } else if (hasMoreVideos) {
      setPageNumber((prev) => pageNumber + 1);
    }
  };

  // previous video play function
  const handlePlayPreviousVideo = () => {
    const index = playIndex - 1;
    if (index >= 0 && index < seriesVideos?.length) {
      const video = seriesVideos[index];
      setDownloadVideoUrl(video?.content?.original_media_url);
      setVideoSource(video.content.h264_media_url || video.content.url);
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    }
  };

  // on video end
  const handleOnVideoEnd = (seriesVideos) => {
    handlePlayNextVideo(-1, seriesVideos);
  };

  // on Video Close Modal
  // const handleVideoModal = (videoSource, videoTitle, playIndex) => {
  //   if (showVideo) {
  //     setShowVideo(false);
  //     setPlayIndex(0);
  //     setVideoSource(false);
  //     return;
  //   } else {
  //     setVideoSource(videoSource);
  //     setVideoTitle(videoTitle);
  //     setShowVideo((prev) => !prev);
  //     setPlayIndex(playIndex);
  //     playerRef.current = null;
  //   }
  // };

  const handleGetVideos = async (page, slug) => {
    try {
      setShowVideo(true);
      setShowVideosLoading(true);
      const url = `${apiGateway}${SERIES_V1_1}${slug}/units/?page=1&lang=en&page_size=${page}`;
      const { data, status } = await axiosPrivate.get(url);
      if (data && status === 200) {
        setChecksState({
          audioCheck: approvedChecks?.is_audio_checked || false,
          subtitleCheck: approvedChecks?.is_subtitle_checked || false,
          videoCheck: approvedChecks?.is_video_checked || false,
          seekhoTShirtCheck: approvedChecks?.is_tshirt_checked || false,
        });
        setHasMoreVideos(data?.has_more);
        if (data?.content_units) {
          setSeriesVideos([...data.content_units]);
          // const video = data.content_units[0];
          setPlayIndex(0);
          handlePlayNextVideo(0, data.content_units);
        }
      }
    } catch (error) {
      setShowVideosLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    } finally {
      setShowVideosLoading(false);
    }
  };

  const playAllVideos = (n_unit, s_slug) => {
    handleGetVideos(n_unit, s_slug);
  };

  const handleDownload = ({ src }) => {
    const link = document.createElement("a");
    link.href = src;
    link.download = `${title}-by-seekho-official.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Common API function call
  const handleSubmitChecksAndRemarkAPICall = async ({
    url,
    payload,
    source,
  }) => {
    try {
      const checkRemarkSource = source === "remark";
      setIsLoading({
        ...isLoading,
        remark: checkRemarkSource ? true : false,
        checks: !checkRemarkSource,
      });
      const response = await axiosPrivate.post(url, payload);
      const { data } = response;
      if (data) {
        if (checkRemarkSource) setAddRemark("");
        setIsLoading({ ...isLoading, remark: false, checks: false });
        handleGetUpdatedSeries();
        setToastMessage({
          type: "success",
          message: checkRemarkSource
            ? "Remark Added Successfully"
            : "Video Checks Approved",
        });
      }
      return response;
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, checks: false, remark: false }));
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleSubmitChecksAndRemark = async ({ source }) => {
    const url = `${apiGateway}/api/v1/cms/${slug}/quality-review/`;
    // When click on save remark
    if (source === "remark") {
      if (addRemark) {
        const payload = {
          remarks: {
            [handleFormatTimeStamp({ seconds: Math.floor(timeStamp) })]:
              addRemark,
          },
        };
        await handleSubmitChecksAndRemarkAPICall({
          url,
          payload,
          source,
        });
      } else {
        setToastMessage({
          type: "error",
          message: "Please add remark to save",
        });
        return;
      }
    } else {
      // When click on save checks
      const payload = {
        is_audio_checked: checksState.audioCheck,
        is_subtitle_checked: checksState.subtitleCheck,
        is_video_checked: checksState.videoCheck,
        is_tshirt_checked: checksState.seekhoTShirtCheck,
      };
      await handleSubmitChecksAndRemarkAPICall({ url, payload });
    }
  };

  return (
    <div>
      <div
        className={` relative cursor-pointer ${thumbnailWrapperClassName}`}
        onClick={() => n_units > 0 && playAllVideos(n_units, slug)}
      >
        <img
          src={thumbnail || placeholder}
          alt={title}
          className={`w-full h-full !cursor-pointer ${thumbnailClassName}`}
        />
        {n_units > 0 && (
          <div
            className="absolute inset-0 flex items-center justify-center"
            style={{ pointerEvents: "none" }}
          >
            <FontAwesomeIcon
              icon={faPlayCircle}
              className={`text-white text-4xl ${playIconClassName} opacity-[85%]`}
            />
          </div>
        )}
      </div>

      {showVideo && (
        <CustomModal
          title={
            videoTitle?.length > 60
              ? videoTitle?.substr(0, 60) + "..."
              : videoTitle
          }
          show={showVideo}
          onHide={() => {
            setShowVideo(false);
          }}
          isButtonsRequired={false}
          className={`${isRemarkInput ? "min-w-[48%]" : ""}`}
        >
          <div className={`${isRemarkInput ? "flex gap-x-4" : ""}`}>
            <div
              className={` ${
                isRemarkInput ? "w-[45%]" : "!w-[67%]"
              } mx-auto modal-video-player`}
            >
              <div className="icon">
                {playIndex > 0 && (
                  <div className="prev-icon" onClick={handlePlayPreviousVideo}>
                    <FontAwesomeIcon
                      size="2x"
                      className="icon"
                      icon={faChevronCircleLeft}
                    />
                  </div>
                )}
                {(playIndex < seriesVideos.length - 1 || hasMoreVideos) && (
                  <div
                    className="next-icon"
                    onClick={() => handlePlayNextVideo(-1, seriesVideos)}
                  >
                    <FontAwesomeIcon
                      size="2x"
                      className="icon"
                      icon={faChevronCircleRight}
                    />
                  </div>
                )}
              </div>
              {showVideosLoading ? (
                <div className="center h-full">
                  <CircularProgress className="!text-baseBlueColor" />
                </div>
              ) : videoSource ? (
                <div className="relative">
                  {downloadVideoUrl && (
                    <FontAwesomeIcon
                      icon={faDownload}
                      onClick={() => handleDownload({ src: downloadVideoUrl })}
                      className="absolute top-1 z-[9999] right-1 cursor-pointer !text-[25px] p-[6px] rounded-lg text-white bg-black opacity-[60%]"
                    />
                  )}
                  <VideoPlayerCustom
                    showNextPrev={true}
                    controlProps={{
                      playIndex: playIndex,
                      seriesVideos: seriesVideos,
                      hasMoreVideos: hasMoreVideos,
                      handlePlayPreviousVideo: handlePlayPreviousVideo,
                      handlePlayNextVideo: (seriesVideos) =>
                        handlePlayNextVideo(-1, seriesVideos),
                    }}
                    src={videoSource}
                    controls={true}
                    muted={false}
                    autoplay={true}
                    onEnded={handleOnVideoEnd}
                    onTimeUpdate={() => {}}
                    videoRef={videoRef}
                    currentTime={0}
                    setTimeStamp={setTimeStamp}
                  />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center">
                  <NoFeedbackSvj
                    className="w-[200px] mx-auto mt-40"
                    displayText="Source not found or Something went wrong!!"
                    displayTextClass="text-[12px] text-gray-500 mt-1"
                  />
                </div>
              )}
            </div>

            {isRemarkInput && (
              <div className="w-[50%] h-full overflow-y-auto">
                <h6 className="text-[14px]">
                  <span className=""> Time Stamp :</span>{" "}
                  <span className="text-green-500 font-bold">
                    {handleFormatTimeStamp({ seconds: Math.floor(timeStamp) })}
                  </span>
                </h6>
                <textarea
                  rows={6}
                  value={addRemark}
                  placeholder="Video review remark"
                  className="border w-full focus:outline-none rounded-md p-2 mt-2 placeholder:text-[13px] text-[14px]"
                  onChange={(e) => setAddRemark(e.target.value)}
                />

                <div className="flex gap-x-2 mt-2">
                  <button
                    className="px-3 py-1 text-[12px] rounded-md bg-gray-100"
                    onClick={() => setAddRemark("")}
                  >
                    Clear
                  </button>
                  <button
                    className="px-1 py-1.5 text-[12px] rounded-md bg-baseBlueColor text-white w-[100px]"
                    onClick={() =>
                      handleSubmitChecksAndRemark({ source: "remark" })
                    }
                  >
                    {isLoading?.remark ? (
                      <CircularProgress className="text-white mt-1" size={10} />
                    ) : (
                      "Save remark"
                    )}
                  </button>
                </div>

                <div className="mt-4">
                  <h6 className="pb-3 text-[14px] text-gray-500 font-semibold">
                    Required Checks
                  </h6>
                  <AllVideoChecks
                    isLoading={isLoading.checks}
                    checksState={checksState}
                    setChecksState={setChecksState}
                    handleSubmitChecksAndRemark={handleSubmitChecksAndRemark}
                  />
                </div>
              </div>
            )}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default VideoPlayOnSeriesClick;
