import { useState } from "react";
import CustomReadMore from "../Common/CustomReadMore";

const Remarks = ({ allRemarks }) => {
  const [readMore, setReadMore] = useState(false);

  const handleToggleReadMore = (index) => {
    setReadMore((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="mt-4">
      <h6 className="text-[15px] font-semibold">Video Review Remarks</h6>
      <div className="p-1 mt-2 border-t">
        {allRemarks?.length > 0 ? (
          allRemarks?.map((item, index) => {
            return (
              <div className="flex gap-x-2 first:!mt-2 mt-3 items-center">
                <span className="p-1 text-[11px] rounded-md text-white font-semibold bg-baseBlueColor">
                  {item?.time}
                </span>
                <CustomReadMore
                  index={index}
                  displayText={item?.remark}
                  readMore={readMore}
                  numberOfCharactersToShow={150}
                  handleToggleReadMore={() => handleToggleReadMore(index)}
                />
              </div>
            );
          })
        ) : (
          <p className="text-[14px] p-1">No Remarks To Display</p>
        )}
      </div>
    </div>
  );
};
export default Remarks;
