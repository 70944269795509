const videoDetailConfirmationDefaultState = {
  unPublish: false,
  delete: false,
  isLoading: false,
  publishNow: false,
  seriesSlug: "",
};

const checksDefaultState = {
  videoCheck: false,
  audioCheck: false,
  subtitleCheck: false,
  seekhoTShirtCheck: false,
};

export { videoDetailConfirmationDefaultState, checksDefaultState };
